import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import BillingForm from './billingForm';
import AccountManager from '../../managers/Account';

class AccountBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountData: null,
      modalMode: '',
    };
  }

  getAccountDetails = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData) {
      this.setState({
        accountData,
      });
    }
  }

  async componentDidMount() {
    // get billingInfo if it exists
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData) {
      this.setState({
        accountData,
      });
    }
    // console.log(accountData);
    // if (accountData && accountData.billingInfo) {
    //   console.log(accountData.billingInfo);
    //   this.setState({
    //     billingAddress: accountData.billingInfo.address || '',
    //     billingCity: accountData.billingInfo.city || '',
    //     billingCode: accountData.billingInfo.zip || '',
    //     country: accountData.billingInfo.country || '',
    //     region: accountData.billingInfo.state || '',
    //     isLoading: false,
    //     hasBillingInfo: true,
    //     hasPaymentMethod: !!accountData.billingInfo.hasPaymentMethod,
    //   });
    // } else {
    //   this.setState({
    //     isLoading: false,
    //     hasBillingInfo: false,
    //   });
    // }
  }

  onBillingSuccess = async () => {
    await AccountManager.get(AccountManager.getToken(), true);
    PubSub.publish('account_updated');
  }

  onCardSuccess = () => {
    this.setState({
      modalMode: 'purchase',
    });
  }

  render() {
    const {
      accountData, modalMode,
    } = this.state;
    return (
      <div className="account-box -account-section">
        {accountData
        && (
        <div>
          <BillingForm
            onSuccess={() => this.onBillingSuccess()}
            onCardSuccess={() => this.onCardSuccess()}
            accountInfo={accountData}
            mode={modalMode} />
        </div>
        )}
      </div>
    );
  }
}

export default AccountBilling;
