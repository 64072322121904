import React, { Component } from 'react';
import { Outlet, Link } from 'react-router-dom';
import SlimHero from '../components/hero/slimHero';
import AccountManager from '../managers/Account';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      accountDetails: null,
      currentSection: '',
    };
  }

  async componentDidMount() {
    document.body.classList.add('-account-background');
    if (AccountManager.isLoggedIn()) {
      // get account info
      const accountData = await AccountManager.get(AccountManager.getToken());
      this.setState({
        accountDetails: accountData,
      });
    } else {
      window.location = '/';
    }
    this.setState({
      currentSection: window.location.pathname.split('/')[2] || 'details',
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('-account-background');
  }

  changeTab = (section) => {
    this.setState({
      currentSection: section,
    });
  }

  render() {
    const { creatorData, accountDetails, currentSection } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <SlimHero creator={creatorData} />
          <div className="account-container__row">
            <div className="account-container__col">
              <div className="account-nav">
                <Link
                className={currentSection === 'collection' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/collection"
                onClick={() => this.changeTab('collection')}>
                  My Collection
                </Link>
                <Link
                className={currentSection === 'details' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/details"
                onClick={() => this.changeTab('details')}>
                  Contact Information
                </Link>
                <Link
                className={currentSection === 'password' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/password"
                onClick={() => this.changeTab('password')}>
                  Account Password
                </Link>
                <Link
                className={currentSection === 'billing' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/billing"
                onClick={() => this.changeTab('billing')}>
                  Billing Details
                </Link>
                {/* <Link
                className={currentSection === 'wallet' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/wallet"
                onClick={() => this.changeTab('wallet')}>
                  Wallet Settings
                </Link> */}
                {/* <Link
                className={currentSection === 'verify' ? 'account-nav__item -selected' : 'account-nav__item'}
                to="/account/verify"
                onClick={() => this.changeTab('verify')}>
                Verify Identity
              </Link> */}
              </div>
            </div>
            {accountDetails
              ? (
                <div className="account-container__col">
                  <div className="account-intro">
                    <h2 className="account-heading">
                      {'Hello '}
                      {accountDetails.firstName}
                    </h2>
                    <p className="account-subheading">
                      Here you can view and make changes to your ethos account.
                    </p>
                  </div>
                  <Outlet />
                </div>
              )
              : <div>Loading account data...</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
