import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

export default function ApplePay(props) {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { agreeTerms, totalPrice } = props;

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: totalPrice,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        // might have to get the terms field the old fashioned way now
        // const terms = document.getElementById('registerAcceptTerms');
        // if (!terms.checked) {
        //   toast.error('Please agree to the terms and conditions.');
        //   e.complete('success');
        // }

        const { onApplePay } = props;
        e.complete('success');
        onApplePay(e);
      });

      pr.on('cancel', async () => {
        console.log('cancelled payment');
      });
    }
  }, [stripe, agreeTerms]);

  if (paymentRequest) {
    return (
      <div>
        <div className="form-cta__separator">
          or
        </div>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </div>
    );
  }

  // Use a traditional checkout form.
  return '';
}

ApplePay.propTypes = {
  onApplePay: PropTypes.func,
  agreeTerms: PropTypes.string,
  totalPrice: PropTypes.string,
};
