import React, { Component } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import ApplePay from './applePay';

const stripePromise = loadStripe(process.env.STRIPE_KEY);

class AlternatePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  processPayment = (paymentInfo) => {
    const { onPaymentProcessing } = this.props;
    onPaymentProcessing(paymentInfo.paymentMethod.id);
  }

  render() {
    const {
      agreeTerms, totalPrice,
    } = this.props;
    return (
      <div className="alternate-payments">
        <Elements stripe={stripePromise}>
          <ApplePay
            onApplePay={(paymentInfo) => this.processPayment(paymentInfo)}
            agreeTerms={agreeTerms}
            totalPrice={totalPrice} />
        </Elements>
      </div>
    );
  }
}

AlternatePayment.propTypes = {
  agreeTerms: PropTypes.string,
  totalPrice: PropTypes.string,
  onPaymentProcessing: PropTypes.func,
};

export default AlternatePayment;
