import React, { Component } from 'react';

class AccountVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        Account Verification
      </div>
    );
  }
}

export default AccountVerify;
