import React, { Component } from 'react';

class SlimHero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="hero-slim">
        &nbsp;
      </div>
    );
  }
}

export default SlimHero;
