import React, { Component } from 'react';

class FullPageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="full-site-loader">
        <div className="full-site-loader__content">
          <img src="https://ipfs.ethosnft.com/chapter2bikes/logo-chapter2bikes.svg" alt="home logo" width="150" />
          <div className="full-site-loader__text">Loading Drop...</div>
        </div>
      </div>
    );
  }
}

export default FullPageLoader;
