import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Throbber from '../throbber';
import Account from '../../managers/Account';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorId: process.env.CREATOR_ID,
      loginEmail: '',
      resetEmail: '',
      loginPassword: '',
      isLoading: false,
      resetPassword: false,
      resetSuccess: false,
      loginVerification: '',
      loadingText: 'Logging you in! One second...',
      enableMFA: false,
      showMFA: false,
      userId: '',
    };
  }

  validateForm = () => {
    const { loginEmail } = this.state;
    const { loginPassword } = this.state;
    const { onFail } = this.props;
    if (loginEmail === '' || loginPassword === '') {
      onFail('Please enter an email and password.');
      return false;
    }
    if (!emailPattern.test(loginEmail)) {
      onFail('Please enter a valid email address.');
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  logIn = async (e) => {
    const { loginEmail, loginPassword } = this.state;
    const { onSuccess, onFail } = this.props;
    let payload = {};
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      payload = {
        email: loginEmail,
        password: loginPassword,
      };
      const login = await Account.login(payload);
      // if MFA is enabled
      if (login && login.userId) {
        this.setState({
          userId: login.userId,
          showMFA: true,
          isLoading: false,
        });
        const mfaField = document.getElementById('loginVerification');
        mfaField.focus();
      // if MFA is NOT enabled
      } else if (login && login.token) {
        await Account.get(login.token);
        Account.setLoginState(login.token);
        onSuccess();
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
        onFail('Incorrect email or password.');
      }
    }
  }

  mfaLogIn = async (e) => {
    const { userId, loginVerification } = this.state;
    const { onSuccess, onFail } = this.props;
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const payload = {
      userId,
      token: loginVerification,
    };
    const login = await Account.mfaLogin(payload);
    if (login && login.token) {
      await Account.get(login.token);
      Account.setLoginState(login.token);
      onSuccess();
    } else {
      this.setState({
        isLoading: false,
      });
      // toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
      onFail('Incorrect verification code.');
    }
  }

  onEnterKey = (e) => {
    const { showMFA } = this.state;
    if (e.keyCode === 13) {
      if (showMFA) {
        this.mfaLogIn(e);
      } else {
        this.logIn(e);
      }
    }
  };

  componentDidMount() {
    const loginField = document.getElementById('loginEmail');
    loginField.focus();
  }

  resetPass = (e) => {
    e.preventDefault();
    this.setState({
      resetPassword: true,
    });
  }

  closeModal = () => {
    const { onRegisterButton } = this.props;
    onRegisterButton();
  }

  sendResetEmail = async (e) => {
    const { resetEmail, creatorId } = this.state;
    const { onFail } = this.props;
    const that = this;
    e.preventDefault();
    if (!emailPattern.test(resetEmail)) {
      onFail('Please enter a valid email address.');
    } else {
      const payload = {
        creatorId,
        emailAddress: resetEmail,
        site: window.location.protocol + '//' + window.location.host,
      };
      this.setState({
        loadingText: 'Preparing your reset password email. One sec...',
        isLoading: true,
      });
      const resetPass = await Account.resetPassword(payload);
      if (resetPass && resetPass.success) {
        that.setState({
          isLoading: false,
          resetSuccess: true,
        });
      } else {
        onFail('Oops! Something went wrong. Please try again later.');
      }
    }
  }

  render() {
    const {
      loginEmail, loginPassword, isLoading, resetPassword, resetEmail, resetSuccess, loadingText, loginVerification, showMFA,
    } = this.state;
    return (
      <div>
        {isLoading
              && <Throbber throbberText={loadingText} />}
        {!resetPassword
          ? (
            <div>
              {showMFA
                ? (
                  <div>
                    <div className="account-subheading">
                      Please enter the verification code we sent to your phone. This code will expire in 30 minutes.
                    </div>
                    <div className="form-container">
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="loginVerification">Verification Code</label>
                        <input
                          className="input-verification"
                          id="loginVerification"
                          type="text"
                          value={loginVerification}
                          maxLength="6"
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                    </div>
                    <div className="form-cta">
                      <button
                        type="submit"
                        onClick={(e) => this.mfaLogIn(e)}>
                        Log in
                      </button>
                    </div>
                    <div className="form-help">
                      {'Didn\'t receive a code?'}
                      {' '}
                      Contact our support team.
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div className="account-subheading -slim-padding">
                      Already have an ethos account?
                      <br />
                      Enter your email and password to log in.
                    </div>
                    <div className="form-container">
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="loginEmail">Email</label>
                        <input
                          className="input-block -inverse"
                          id="loginEmail"
                          type="text"
                          autoComplete="new-email"
                          value={loginEmail}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="loginPassword">Password</label>
                        <input
                          className="input-block -inverse"
                          id="loginPassword"
                          autoComplete="new-password"
                          type="password"
                          value={loginPassword}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                    </div>
                    <div className="form-cta">
                      <button
                        type="submit"
                        onClick={(e) => this.logIn(e)}>
                        Log in
                      </button>
                    </div>
                    <div className="form-help">
                      Trouble logging in?
                      {' '}
                      <a
                    href="#"
                    onClick={(e) => this.resetPass(e)}>
                        Click here
                      </a>
                      .
                    </div>
                    <div className="account-register__cta">
                      <div className="account-subheading -slim-padding">
                        {'Don\'t have an ethos account?'}
                      </div>

                      <div className="form-cta">
                        <Link
                          to="/register"
                          className="button button-register"
                          onClick={() => this.closeModal()}>
                          Register Now
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )
          : (
            <div>
              <div className="account-heading">
                Reset Password
              </div>

              {resetSuccess
                ? (
                  <div>
                    <div className="account-subheading">
                      Instructions on resetting your password have been sent to your inbox.
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div className="account-subheading">
                      To reset your password, enter your email address below.
                      <br />
                      {'If this email is attached to an ethos account, we\'ll send you instructions to your inbox.'}
                    </div>

                    <div className="form-container">
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="resetEmail">Email</label>
                        <input
                          className="input-block"
                          id="resetEmail"
                          type="text"
                          value={resetEmail}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                    </div>
                    <div className="form-cta">
                      <button
                        type="submit"
                        onClick={(e) => this.sendResetEmail(e)}>
                        Reset my password
                      </button>
                    </div>
                  </div>
                )}

            </div>
          )}
      </div>
    );
  }
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  onRegisterButton: PropTypes.func,
};

export default LoginForm;
