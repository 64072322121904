import React, { Component } from 'react';

class VimeoVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="featured-drop__outer">
        <div className="video-inner">
          <iframe
            src="https://player.vimeo.com/video/729133345?h=c0836a39c5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute', top: '0', left: '0', width: '100%', height: '100%',
            }}
            title="Chapter2 | B Harms">
          </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    );
  }
}

export default VimeoVideo;
