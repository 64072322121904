import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Creator {
  constructor() {
    this.creatorData = null;
    this.assetData = null;
    this.dropData = null;
    this.creatorId = process.env.CREATOR_ID;
    this.assetId = process.env.ASSET_ID;
    this.assetId2 = process.env.ASSET2_ID;
    this.dropId = process.env.DROP_ID;
    this.dropId2 = process.env.DROP2_ID;
    this.apiUrl = process.env.SERVER_URL;
  }

  createRisk = (payloadData, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/create', payloadData, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  createDecision = (payloadData, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/decision', payloadData, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  confirmPurchase = (payload, token) => {
    const that = this;
    const url = that.apiUrl + 'payment/pay';
    return Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getSingleDrop = (dropId) => {
    // this needs to return a dropData object that contains a drop and asset property
    const that = this;
    const dropData = {
      drop: null,
      asset: null,
    };
    return Ajax.get(that.apiUrl + 'drop/' + dropId)
      .then((data) => {
        if (data.assets) {
          dropData.asset = data.assets[0];
        }
        dropData.asset.drops.forEach((drop) => {
          if (drop.dropId === dropId) {
            dropData.drop = drop;
          }
        });
        return dropData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getDrop = () => {
    const that = this;
    let assetData = null;
    let assetData2 = null;
    let dropData = null;
    let dropData2 = null;
    if (this.creatorData !== null) {
      return this.creatorData;
    }

    return Ajax.get(that.apiUrl + 'creator/' + this.creatorId)
      .then((data) => {
        data.assets.forEach((asset) => {
          if (asset.assetId === that.assetId) {
            assetData = asset;
          }
        });
        if (that.assetId2) {
          data.assets.forEach((asset) => {
            if (asset.assetId === that.assetId2) {
              assetData2 = asset;
            }
          });
        }
        assetData.drops.forEach((drop) => {
          if (drop.dropId === that.dropId) {
            dropData = drop;
          }
        });
        if (that.dropId2) {
          assetData2.drops.forEach((drop) => {
            if (drop.dropId === that.dropId2) {
              dropData2 = drop;
            }
          });
        }
        const creatorData = {
          creator: data,
          asset: assetData,
          asset2: assetData2,
          drop: dropData,
          drop2: dropData2,
        };
        that.creatorData = creatorData;
        return creatorData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Creator();
