import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment-mini';

class HomeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownData: null,
      friendlyDate: null,
    };
  }

  setHeroHeight = () => {
    const hero = document.getElementById('landingHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  parseCountdown = (date) => {
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  componentDidMount() {
    const { data } = this.props;
    const that = this;
    this.setHeroHeight();
    window.addEventListener('resize', that.setHeroHeight);
    that.parseCountdown(data.drop.dateOpen);
  }

  learnMore = (e) => {
    const { onLearnMore } = this.props;
    e.preventDefault();
    onLearnMore();
  }

  goToFaq = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  render() {
    const { type } = this.props;
    const { countdownData } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div>
          <div className="countdown-item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div>
        <div id="landingHero" className="landing-hero">
          <div className="landing-hero__inner">
            {type === 'countdown'
                && <div className="hero-title__logo"><img src="https://ipfs.ethosnft.com/chapter2bikes/logo-nav-hero.png" alt="Chapter 2 Bikes" /></div>}
            <div className="landing-hero__text -center-vert">
              <h2 className="hero-subtitle">KOKO AERO FRAME</h2>
              <h1 className="hero-title">ARTIST EDITION</h1>
              <div className="hero-secondary-logo"><img src="https://ipfs.ethosnft.com/chapter2bikes/logo-bharms-sig.png" alt="Chapter 2 Bikes" /></div>

            </div>
          </div>
        </div>
        <div className="featured-drop__outer">
          <div className="countdown-container">
            <h2 className="countdown-header">CYCLING MEETS ART</h2>
            <p className="hero-description">
              For the past number of years, Bradley Harms has taken
              a leading role in a new and forward-looking wave of
              Canadian abstraction, building upon traditions within
              the medium, while creating work that both reflects and
              critiques contemporary social and technological
              developments.
            </p>
            {/* <p className="hero-description">
              NFTs will drop at
              {' '}
              {friendlyDate}
              .
            </p> */}
            <a href="#" className="button-learn-more" onClick={(e) => this.learnMore(e)}>Learn More</a>
            {type === 'countdown'
                && (
                <div className="hero-countdown__inline">
                  {countdownData
                  && (
                  <Countdown
                  date={countdownData}
                  renderer={renderer} />
                  )}
                </div>
                )}
            {/* {type === 'countdown'
                  ? <a href="#" className="button-hero" onClick={(e) => this.learnMore(e)}>Learn More</a>
                  : <Link className="button-hero" to={'/drop/' + data.drop.dropId}>View Details</Link>} */}
          </div>
        </div>
      </div>
    );
  }
}

HomeHero.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
  onLearnMore: PropTypes.func,
  onFaqButton: PropTypes.func,
};

export default HomeHero;
