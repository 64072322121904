import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import Account from '../../managers/Account';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class AccountLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmail: '',
      loginPassword: '',
      isLoading: false,
      resetPassword: false,
    };
  }

  validateForm = () => {
    const { loginEmail } = this.state;
    const { loginPassword } = this.state;
    if (loginEmail === '' || loginPassword === '') {
      toast.error('Please enter an email and password.');
      return false;
    }
    if (!emailPattern.test(loginEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  logIn = async (e) => {
    const { loginEmail } = this.state;
    const { loginPassword } = this.state;
    let payload = {};
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      payload = {
        email: loginEmail,
        password: loginPassword,
      };
      const login = await Account.login(payload);
      if (login && login.token) {
        await Account.get(login.token);
        Account.setLoginState(login.token);
        window.location.href = '/';
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
        toast.error('Incorrect email or password.');
      }
    }
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.logIn(e);
    }
  };

  resetPass = (e) => {
    e.preventDefault();
    this.setState({
      resetPassword: true,
    });
  }

  componentDidMount() {}

  render() {
    const { loginEmail } = this.state;
    const { loginPassword } = this.state;
    const { isLoading } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-box -center">
          {isLoading
              && <Throbber throbberText="Loggin you in! One second..." />}
          <div>
            <div className="account-heading">
              Log In
            </div>
            <div className="account-subheading">
              Enter your email and password to log in.
              <br />
              {'Don\'t have an account? '}
              <a href="/register">click here</a>
              !
            </div>
            <div className="form-container">
              <div className="form-section">
                <label className="label-block -inverse" htmlFor="loginEmail">Email</label>
                <input
                  className="input-block"
                  id="loginEmail"
                  type="text"
                  value={loginEmail}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
              </div>
              <div className="form-section">
                <label className="label-block -inverse" htmlFor="loginPassword">Password</label>
                <input
                  className="input-block"
                  id="loginPassword"
                  type="password"
                  value={loginPassword}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
              </div>
            </div>
            <div className="form-cta">
              <button
                type="submit"
                onClick={(e) => this.logIn(e)}>
                Log In
              </button>
            </div>
            <div className="form-help">
              Trouble logging in?
              {' '}
              <a
                href="#"
                onClick={(e) => this.resetPass(e)}>
                Click here
              </a>
              .
            </div>
          </div>
          <div>
            <div className="account-heading">
              Reset Password
            </div>
            <div className="account-subheading">
              To reset your password, enter your email address below.
              {'If this email is attached to an ethos account, we\'ll send you instructions to your inbox.'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountLogin;
