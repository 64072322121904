import React from 'react';
import {
  ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';

class CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOnCard: '',
      isLoading: false,
      stripeStyle: {
        iconColor: '#c4f0ff',
        color: window.location.href.includes('account') ? '#ffffff' : '#232323',
        fontWeight: '500',
        fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
      },
    };
  }

  async componentDidMount() {
    const accountData = await AccountManager.get(AccountManager.getToken());
    this.setState({
      nameOnCard: accountData.firstName + ' ' + accountData.lastName,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    const { nameOnCard } = this.state;
    const { onSuccess } = this.props;

    const cardElement = elements.getElement('cardNumber');

    const paymentMethod = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: nameOnCard,
      },
    };

    this.setState({
      isLoading: true,
    });

    const results = await stripe.createPaymentMethod(paymentMethod);
    if (results && results.paymentMethod) {
      const payload = {
        id: results.paymentMethod.id,
      };
      const saveCard = await AccountManager.saveCardDetails(process.env.SERVER_URL + 'payment/method', payload, AccountManager.getToken());
      
      if (saveCard && saveCard.success === false) {
        this.setState({
          isLoading: false,
        });
        toast.error(saveCard.message || 'Oops! There was a problem with that card. Please try a different credit card.');
      } else {
        toast.success('Credit card settings saved!');
        PubSub.publish('card_updated');
        await AccountManager.get(AccountManager.getToken(), true);
        PubSub.publish('account_updated');
        if (onSuccess) {
          onSuccess();
        }
        this.setState({
          isLoading: false,
        });
      }
    } else {
      toast.error(results.error ? results.error.message : 'Oops! Something went wrong. Try again later.');
      this.setState({
        isLoading: false,
      });
    }
  };
  
  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  render() {
    const { nameOnCard, stripeStyle, isLoading } = this.state;
    const { stripe, elements } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        {isLoading
          && <Throbber throbberText="Updating your billing details! Please stand by..." />}
        <div>
          <div className="form-section">
            <div className="label-block">Name on card:</div>
            <input
                className="input-block"
                id="nameOnCard"
                type="text"
                value={nameOnCard}
                onChange={(e) => this.handleInputChange(e)} />
          </div>
          <div className="form-section">
            <div className="label-block">Number on card:</div>
            <div className="stripe-element">
              <CardNumberElement
                options={{
                  style: {
                    base: stripeStyle,
                  },
                }} />
            </div>
          </div>
          <div className="form-section">
            <div className="label-block">Expiry:</div>
            <div className="stripe-element">
              <CardExpiryElement
                options={{
                  style: {
                    base: stripeStyle,
                  },
                }} />
            </div>
          </div>
          <div className="form-section">
            <div className="label-block">CVC:</div>
            <div className="stripe-element">
              <CardCvcElement
                options={{
                  style: {
                    base: stripeStyle,
                  },
                }} />
            </div>
          </div>
          <div className="form-cta">
            {stripe && elements
              && (
              <button type="submit">
                Save Card
              </button>
              )}
          </div>
        </div>
      </form>
    );
  }
}

export default function InjectedCardForm(props) {
  const { onSuccess } = props;
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardForm onSuccess={onSuccess} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}

CardForm.propTypes = {
  stripe: PropTypes.string,
  elements: PropTypes.string,
  onSuccess: PropTypes.string,
};
