import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  componentDidMount() {
    if (window.location.href.includes('account/')) {
      this.setState({
        isSticky: true,
      });
    }
  }

  render() {
    const { isSticky } = this.state;
    return (
      <div className={isSticky ? 'footer -fixed' : 'footer -fixed'}>
        <div className="footer-inner">
          View smart contract on
          {' '}
          <a href={process.env.SMART_CONTRACT} target="_blank" rel="noreferrer">Flowscan</a>
          .
          <div className="footer-inner__right">
            Powered by
            {' '}
            <a href="https://www.ethosnft.com" target="_blank" rel="noreferrer">ethos</a>
            .
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
