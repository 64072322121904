import React, { Component } from 'react';
import AccountLogin from '../components/account/login';
import SlimHero from '../components/hero/slimHero';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <SlimHero />
        <AccountLogin />
      </div>
    );
  }
}

export default Login;
