import React, { Component } from 'react';
import AccountRegister from '../components/account/register';
import SlimHero from '../components/hero/slimHero';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <SlimHero />
        <AccountRegister />
      </div>
    );
  }
}

export default Register;
