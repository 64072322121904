import React, { Component } from 'react';
import ResetPassword from '../components/account/resetPassword';
import SlimHero from '../components/hero/slimHero';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <SlimHero />
        <ResetPassword />
      </div>
    );
  }
}

export default PasswordReset;
