import React, { Component } from 'react';
import FlowWallet from './flowWallet';

class AccountWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
    };
  }

  updateWallet = (user) => {
    const { isLoggingIn } = this.state;
    const payload = {
      blockchain: 'flow',
      walletType: 'blocto',
      walletAddress: null,
    };
    if (isLoggingIn) {
      payload.walletAddress = user.addr;
      console.log('User just connected a wallet');
      console.log(payload);
      // TODO: Save the wallet address to our database
    }
  }

  onLogInAttempt = () => {
    console.log('User is attempting to connect a wallet');
    this.setState({
      isLoggingIn: true,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="account-box -account-section">
          <h2 className="account-heading__h2">
            Wallet Settings
          </h2>
          <div className="account-subheading">
            Change or update your wallet settings here
          </div>
          <div>
            <FlowWallet
              onWalletConnection={(user) => this.updateWallet(user)}
              onLogInAttempt={() => this.onLogInAttempt()} />
          </div>
        </div>
      </div>
    );
  }
}

export default AccountWallet;
