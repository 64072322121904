import React, { Component } from 'react';
import moment from 'moment-mini';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';

class AccountCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      collection: [],
    };
  }

  formatThumbnail = (number, imageUrl) => {
    if (process.env.CREATOR_SUBDOMAIN === 'lovejules') {
      const imagePath = 'https://ipfs.ethosnft.com/lovejules/2010-nfth-50-';
      let constructedPath = '';
      if (number < 10) {
        constructedPath = imagePath + '0' + number + '.png';
      } else {
        constructedPath = imagePath + number + '.png';
      }
      return constructedPath;
    }
    return imageUrl;
  }

  formatSerialNumber = (number) => {
    if (process.env.CREATOR_SUBDOMAIN === 'lovejules') {
      const serialPrefix = '2010.NFTH.50.';
      let serialNumber = '';
      if (number < 10) {
        serialNumber = serialPrefix + '0' + number;
      } else {
        serialNumber = serialPrefix + number;
      }
      return serialNumber;
    }
    return number;
  }

  async componentDidMount() {
    const accountCollection = await AccountManager.getCollection(AccountManager.getToken());
    if (accountCollection) {
      this.setState({
        collection: accountCollection,
      });
    }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const { isLoading, collection } = this.state;
    return (
      <div>
        <div className="account-box -account-section">
          {isLoading
          && <Throbber throbberText="Loading collection! Please stand by..." />}
          <h2 className="account-heading__h2">
            Account Collection
          </h2>
          <div className="account-subheading">
            View your ethos purchases here
          </div>
          <div className="collection-container">
            {collection.map((item) => (
              <div className="collection-item" key={item.nftId}>
                {item.asset.mediaMP4
                  ? (
                    // <div className="collection-preview">
                    //   <a href={this.formatThumbnail(item.number, item.asset.mediaMP4)} target="_blank" rel="noreferrer"><img src={this.formatThumbnail(item.number, item.asset.mediaThumbnailUrl)} alt="NFT Preview" /></a>
                    // </div>
                    <div className="collection-preview">
                      <a href={this.formatThumbnail(item.number, item.asset.mediaMP4)} target="_blank" rel="noreferrer">
                        <video autoPlay="autoplay" loop muted>
                          <source src={item.asset.mediaMP4} type="video/mp4" />
                          <track
                          default
                          kind="captions"
                          srcLang="en"
                          src="" />
                          Your browser does not support the video tag.
                        </video>
                      </a>
                    </div>
                  )
                  : (
                    <div className="collection-preview">
                      <a href={this.formatThumbnail(item.number, item.asset.imageUrl || item.asset.mediaUrl)} target="_blank" rel="noreferrer"><img src={this.formatThumbnail(item.number, item.asset.imageUrl || item.asset.mediaUrl)} alt="NFT Preview" /></a>
                    </div>
                  )}

                <div className="collection-details">
                  <div
                    className="collection-name"
                    dangerouslySetInnerHTML={{ __html: item.asset.name }}>
                  </div>
                  <div
                    className="collection-description"
                    dangerouslySetInnerHTML={{ __html: item.asset.description }}>
                  </div>
                  <div className="collection-details__item">
                    Blockchain:&nbsp;
                    <strong>Flow</strong>
                  </div>
                  
                  <div className="collection-details__item">
                    Serial Number:&nbsp;
                    <strong>{this.formatSerialNumber(item.number)}</strong>
                  </div>
                  <div className="collection-details__item">
                    Date purchased:&nbsp;
                    <strong>
                      {moment(item.datePurchased).format('MMMM, DD YYYY')}
                    </strong>
                  </div>
                  <div className="collection-details__item">
                    Amount paid:&nbsp;
                    <strong>
                      $
                      {item.txn.totalAmount}
                    </strong>
                  </div>
                </div>
              </div>
            ))}

            {collection.length === 0
              && <div>{'You haven\'t purchased any NFTs yet.'}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountCollection;
