import React, { Component } from 'react';
import { toast } from 'react-toastify';
import HomeHero from '../components/hero/homeHero';
import HomeDrop from '../components/featuredDrop/homeDrop';
import FAQ from '../components/about/faq';
import CreatorManager from '../managers/Creator';
import FullWidthImage from '../components/hero/fullWidthImage';
import VimeoVideo from '../components/hero/VimeoVideo';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
    };
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    if (dropData) {
      this.setState({
        dropData,
      });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.hash.includes('register=success')) {
      toast.success('Successfully created your account.');
      window.location.hash = '';
    }
    if (window.location.hash.includes('login=success')) {
      toast.success('Log in success.');
      window.location.hash = '';
    }
    await this.getDropData();
  }

  scrollToSection = (element) => {
    const scrollToElement = document.getElementById(element);
    scrollToElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { dropData } = this.state;
    return (
      <div>
        {dropData
        && (
        <div>
          <HomeHero
            data={dropData}
            type="landing"
            onLearnMore={() => this.scrollToSection('moreInfoElement')}
            onFaqButton={() => this.scrollToSection('homeFaq')} />
          <HomeDrop
            layoutStyle="default"
            dropData={dropData.drop}
            assetData={dropData.asset} />
          {dropData.asset2
            && (
            <HomeDrop
              layoutStyle="offset"
              dropData={dropData.drop2}
              assetData={dropData.asset2} />
            )}
          <FullWidthImage />
          <VimeoVideo />
          <FAQ />
        </div>
        )}
      </div>
    );
  }
}

export default Home;
