import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import LoginForm from '../account/loginForm';
import BillingForm from '../account/billingForm';
import ConfirmPurchase from './confirmPurchase';
import CreatorManager from '../../managers/Creator';

class FeaturedDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      accountData: null,
      showModal: false,
      modalMode: '',
      purchaseResult: null,
      creatorId: process.env.CREATOR_ID,
      amountLeft: 0,
    };
    PubSub.subscribe('account_updated', this.onPubSubEvent);
  }

  getAccountDetails = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData) {
      this.setState({
        accountData,
      });
    }
  }

  onPubSubEvent = async () => {
    await this.getAccountDetails();
    if (AccountManager.isLoggedIn()) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  }

  async componentDidMount() {
    const { data } = this.props;
    const hasPurchased = localStorage.getItem(process.env.PURCHASE_COOKIE + data.drop.dropId);
    // check if user is logged in or not.
    if (AccountManager.isLoggedIn()) {
      await this.getAccountDetails();
      this.setState({
        isLoggedIn: true,
        amountLeft: data.drop.quantityAvailable,
        purchaseResult: hasPurchased ? 'success' : null,
      });
    } else {
      this.setState({
        isLoggedIn: false,
        amountLeft: data.drop.quantityAvailable,
      });
    }
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  loginModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
      modalMode: 'login',
    });
  }

  checkBuyStatus = (e) => {
    const { accountData } = this.state;
    let mode = '';
    // check for billing info. If it exists, let them buy. Otherwise prompt to add
    // if (accountData.billingInfo && accountData.billingInfo.hasPaymentMethod) {
    //   mode = 'purchase';
    // } else if (accountData.billingInfo && !accountData.billingInfo.hasPaymentMethod) {
    //   mode = 'card';
    // } else {
    //   mode = 'billing';
    // }
    if (accountData.billingInfo) {
      mode = 'purchase';
    } else {
      mode = 'billing';
    }
    e.preventDefault();
    this.setState({
      showModal: true,
      modalMode: mode,
    });
  }

  loginSuccess = async () => {
    await this.getAccountDetails();
    this.setState({
      showModal: false,
      isLoggedIn: true,
    });
    toast.success('Log in success.');
    PubSub.publish('account_updated');
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  onBillingSuccess = async () => {
    // await AccountManager.get(AccountManager.getToken(), true);
    // update account details
    // console.log('onBillingSuccess');
    // console.log(billingDetails);
    PubSub.publish('account_updated');
    this.setState({
      modalMode: 'purchase',
    });
  }

  onCardSuccess = () => {
    this.setState({
      modalMode: 'purchase',
    });
  }

  attemptPurchase = async (amount, paymentId, cartToken) => {
    const { data } = this.props;
    const { amountLeft } = this.state;
    const parsedDetails = amount;
    parsedDetails.nftName = data.asset.name;
    parsedDetails.nftLink = data.asset.mediaThumbnailUrl || data.asset.mediaUrl;

    // const payload = {
    //   dropId: data.drop.dropId,
    //   amount: amount.rawTotal,
    //   site: window.location.protocol + '//' + window.location.host,
    //   creatorId,
    //   details: parsedDetails,
    // };

    const payload = {
      gasFee: parsedDetails.gasFee,
      dropId: data.drop.dropId,
      nftName: parsedDetails.nftName,
      nftLink: parsedDetails.nftLink,
    };

    if (paymentId) {
      payload.pmId = paymentId;
    }

    if (cartToken) {
      payload.cartToken = cartToken;
    }

    const purchaseData = await CreatorManager.confirmPurchase(payload, AccountManager.getToken());

    if (purchaseData && purchaseData.success) {
      // update the displayed amount remaining
      const newAmountLeft = amountLeft === 0 ? 0 : amountLeft - 1;
      this.setState({
        showModal: false,
        amountLeft: newAmountLeft,
        purchaseResult: 'success',
      });
      gtag('event', 'chapter2bikes_purchase', { // eslint-disable-line
        event_category: 'chapter2bikes_purchase_success',
        event_label: 'User purchased a Chapter 2 Bikes NFT',
      });
      toast.success(purchaseData.message, {
        autoClose: false,
      });
      localStorage.setItem(process.env.PURCHASE_COOKIE + data.drop.dropId, 1);
      // PubSub.publish('drop _updated');
    } else {
      this.setState({
        showModal: false,
      });
      toast.error(purchaseData.message || 'Oops! Something went wrong while processing the payment. Please try again later.', {
        autoClose: false,
      });
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { data } = this.props;
    const {
      isLoggedIn, showModal, modalMode, accountData, purchaseResult, amountLeft,
    } = this.state;
    return (
      <div className="featured-drop__outer">
        <div className="landing-featured-drop">
          <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
            {modalMode === 'login'
            && <LoginForm onSuccess={() => this.loginSuccess()} onFail={(error) => this.loginFailed(error)} onRegisterButton={() => this.closeModal()} />}
            {(modalMode === 'billing' || modalMode === 'card')
            && (
            <div>
              <BillingForm
                onSuccess={() => this.onBillingSuccess()}
                onCardSuccess={() => this.onCardSuccess()}
                accountInfo={accountData}
                mode={modalMode} />
            </div>
            )}
            {modalMode === 'purchase'
            && (
            <div>
              <ConfirmPurchase
                data={data}
                onPurchaseAttempt={(amount, paymentId, cartToken) => this.attemptPurchase(amount, paymentId, cartToken)}
                purchaseResult={purchaseResult}
                accountInfo={accountData}
                onPayWithCreditCard={() => this.setState({ modalMode: 'card' })} />
            </div>
            )}
          </SimpleModal>
          <div className="featured-drop__row">
            <div className="featured-drop__col-image">
              <div className="featured-drop__image">
                {data.asset.mediaMP4
                  ? (
                    <video autoPlay="autoplay" loop muted>
                      <source src={data.asset.mediaMP4} type="video/mp4" />
                      <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                      Your browser does not support the video tag.
                    </video>
                  )
                  : <a href={data.asset.mediaThumbnailUrl || data.asset.mediaUrl} target="_blank" rel="noreferrer"><img src={data.asset.mediaThumbnailUrl || data.asset.mediaUrl} alt="ethos" /></a>}
              </div>
            </div>
            <div className="featured-drop__col-text">
              <div
                className="featured-drop__title"
                dangerouslySetInnerHTML={{ __html: data.asset.name }}>
              </div>
              
              {purchaseResult === 'success'
                ? (
                  <div className="featured-drop__description">
                    <p>
                      Congratulations. Your purchase of
                      {' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: data.asset.name }}>
                      </span>
                      {' '}
                      was successful.
                    </p>
                    
                    <p>Keep an eye on your inbox. An email receipt is on its way.</p>
                    <p>In the meantime, you can view your NFT in your My Account section.</p>
                    <br />
                    <p className="featured-drop__cta"><Link to="/account/collection" className="button">View Your NFT</Link></p>
                  </div>
                )
                : (
                  <div>
                    <div
                  className="featured-drop__description"
                  dangerouslySetInnerHTML={{ __html: data.asset.description }}>
                    </div>
                    <div className="featured-drop__description -padding-bottom">No crypto required. NFTs will be simple to purchase with any major credit card.</div>
                    <div className="featured-drop__volume">
                      {amountLeft}
                      {' '}
                      remain.
                    </div>
                    <div className="featured-drop__amount">
                      $
                      {data.drop.buyItNow}
                      {' '}
                      USD
                    </div>
                    {/* <div className="featured-drop__date">Date the drop is available</div> */}
                    {!isLoggedIn
                      ? (
                        <div className="featured-drop__cta">
                          {amountLeft <= 0
                            ? <a className="button-disabled">Sold Out</a>
                            : <a href="#" className="button" onClick={(e) => this.loginModal(e)}>Buy Now</a>}
                        </div>
                      )
                      : (
                        <div>
                          {amountLeft <= 0
                            ? <a className="button-disabled">Sold Out</a>
                            : <a href="#" className="button" onClick={(e) => this.checkBuyStatus(e)}>Buy Now</a>}
                        </div>
                      )}
                  </div>
                )}
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FeaturedDrop.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
};

export default FeaturedDrop;
